@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ShowMoreLessContent {
    &-Wrapper {
        .ShowMoreLessContent {
            &-Button {
                font-weight: 600;
                color: $default-primary-base-color;
                cursor: pointer;
                width: auto;
                display: inline;
                min-width: auto;
                margin: 0 0 0 3px;
            }
        }
    }

    &-Content {
        *:last-of-type:not(.ShowMoreLessContent-Button) {
            display: inline;
        }
    }
}
