@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --swipeable-drawer-header-background: #{$default-secondary-base-color};
    --swipeable-drawer-header-height: 60px;
    --swipeable-drawer-title-color: #{$white};
    --swipeable-drawer-close-background: #{$default-primary-base-color};
    --swipeable-drawer-close-border-radius: 10px;
}

.SwipeableTemporaryDrawer {
    &-Drawer {
        display: grid;
        grid-template-rows: var(--swipeable-drawer-header-height) auto;
        height: 100%;
    }

    &-Drawer_variant_right {
        .SwipeableTemporaryDrawer {
            &-Header {
                padding: 10px 15px 10px 30px;
            }
            &-Title {
                margin-left: 0;
            }

            &-CloseIcon {
                order: 2;
                margin-left: auto;
            }
        }
    }

    &-Header {
        display: flex;
        align-items: center;
        background: var(--swipeable-drawer-header-background);
        padding: 10px 20px;
        height: var(--swipeable-drawer-header-height);
    }

    &-CloseIcon {
        width: 40px;
        height: 40px;
        border-radius: var(--swipeable-drawer-close-border-radius);
        background: var(--swipeable-drawer-close-background);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:after,
        &:before {
            display: block;
            content: '';
            width: 16px;
            height: 2px;
            background: white;
            position: absolute;
            border-radius: 2px;
        }

        &:after {
            transform: rotate(45deg);
        }

        &:before {
            transform: rotate(-45deg);
        }
    }

    &-Title {
        font-size: 16px;
        font-weight: 600;
        color: var(--swipeable-drawer-title-color);
        margin: 0 0 0 17px;

        @include tablet {
            font-size: 22px;
        }
    }

    &-Content {
        overflow-y: auto;
    }

    &-Drawer {
        &_minicart {
            @include mobileAndTablet {
                width: 100vw;
            }
        }
        &_account {
            @include mobileAndTablet {
                width: 100vw;
            }
            .Form,
            .MyAccountOverlay-Additional {
                @include mobileAndTablet {
                    padding: 24px 20px 0;
                }
            }
            .SwipeableTemporaryDrawer-Title {
                @include mobileAndTablet {
                    display: flex;
                    align-items: center;
                }

                @include tablet {
                    font-size: 22px;
                }
            }
            .SwipeableTemporaryDrawer-Header {
                @include mobileAndTablet {
                    padding: 10px 20px;
                    svg {
                        width: 18px;
                        height: 21px;
                        margin-right: 17px;
                    }
                    svg path {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }
        }
    }
}
