@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.RmaCreate {
    --rma-create-radius: 7px;
    --rma-create-bg: #{$grey-light};
    --rma-create-info-color: #{$default-primary-base-color};
    --rma-create-placeholder-color: #{$default-secondary-base-color};

    &-OrderItem {
        padding: 16px 32px;
        border-top: 1px solid $grey5;

        @include mobile {
            padding: 16px 20px;
        }
        @include from-tablet {
            display: flex;
            align-items: flex-start;
        }
        > div:first-of-type {
            display: flex;
            flex: 1;
            .Field {
                margin-top: 50px;
            }
        }

        &_disabled {
            pointer-events: none;
            background: $grey-light;
        }
    }
    &-OrderItemLabel {
        display: flex;
        align-items: center;
        cursor: pointer;
        .Image {
            min-width: 99px;
            max-width: 99px;
            height: 99px;
            margin: 0 21px 0 25px;

            @include from-tablet {
                min-width: 121px;
                max-width: 121px;
                height: 121px;
            }
        }
    }
    &-OrderItemName,
    &-OrderItemReturnFields label {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        color: $default-primary-base-color;
    }
    &-OrderItemStatus {
        display: block;
        font-size: 12px;
    }
    &-OrderItemReturnFields {
        @include from-tablet {
            width: 50%;
            max-width: 388px;
        }
        @include desktop {
            max-width: 488px;
        }
        margin-bottom: 16px;
        label {
            margin: 24px 0 9px;
        }
        .Field {
            margin-top: 0;
        }
        .Field.Field_type_number {
            label {
                margin-top: 0;
            }
            input,
            + span {
                color: $grey3;
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 15px;
                line-height: 150%;
            }
            input {
                border-radius: 10px;
                border: 1px solid $grey-dark;
                background: $white;
                width: 89px;
                height: 48px;
            }
            + span {
                margin-left: 15px;
            }
            button {
                display: none;
            }
        }
        .Field_type_select {
            .FieldSelect {
                --input-background-color: #{$grey-light};
                --input-border-color: #{$grey-light};
                color: $grey3;

                &::placeholder {
                    color: $grey3;
                }

                select {
                    border-color: $grey3;
                    color: $grey3;
                }
                .ChevronIcon {
                    fill: $grey3;
                }
                .ChevronIcon {
                    right: 24px;
                    fill: $grey3;
                }
            }
            .FieldSelect-Select {
                padding: 12px 40px 12px 24px;
            }
        }
    }
    .RmaCreate-FormContainer {
        background: var(--rma-create-bg);
        padding: 32px;
        border-radius: var(--rma-create-radius);

        @include mobile {
            padding: 20px;
        }
    }
    .RmaCreate-Info {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        margin-bottom: 16px;
        color: var(--rma-create-info-color);
    }

    .Form {
        display: flex;
        @include mobile {
            display: block;
        }
        .FieldForm-Fields {
            flex: 1;
            margin-right: 18px;
            @include mobile {
                margin-right: 0;
            }
        }
        .Field_type_select {
            margin-top: 0;
            .FieldSelect-Select {
                line-height: 20px;
                color: var(--rma-create-placeholder-color);
                --input-border-color: var(--rma-create-placeholder-color);
            }
            .ChevronIcon {
                --input-color: var(--rma-create-placeholder-color);
            }
        }
        .Button {
            --button-height: 55px;
            --button-hover-height: 55px;
        }
    }

    &-OrderInfo,
    &-OrderItems {
        padding: 32px;

        @include mobile {
            padding: 20px;
        }

        h3 {
            letter-spacing: 0.07em;
            font-weight: bold;
            font-size: 22px;
            line-height: 135%;
            font-family: $font-primary;
            color: $default-primary-base-color;
            margin-top: 0;
        }
    }

    &-OrderInfo {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $grey5;
        border-bottom: 0;
        border-radius: 7px 7px 0 0;
    }
    &-OrderInfoHeading {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        button {
            font-family: $font-secondary;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            cursor: pointer;
            color: $default-secondary-base-color;
        }
    }
    &-OrderInfoBase,
    &-OrderInfoAddress {
        @include mobile {
            width: 100%;
        }
        h4 {
            color: $default-primary-base-color;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
            font-family: $font-primary;
            font-weight: bold;
            margin-bottom: 11px;
        }
    }
    &-OrderInfoBase {
        width: 65%;

        span {
            &:nth-child(1) {
                min-width: 150px;
                display: inline-block;
            }
            &:nth-child(2) {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.05em;
                color: $black;
                font-family: $font-secondary;
                font-weight: normal;
            }
        }
    }
    &-OrderInfoAddress {
        width: 35%;

        p {
            margin: 0;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.05em;
            a {
                color: $default-secondary-base-color;
                font-weight: normal;
            }
        }
    }

    &-OrderItems {
        border: 1px solid $grey5;
        border-top: 0;
        border-radius: 0 0 7px 7px;
        padding: 0;
        margin-bottom: 50px;
        h3 {
            padding: 0 32px;
            margin-bottom: 23px;

            @include mobile {
                padding: 0 20px;
            }
        }
    }
    // needed use multiple classes to override styles
    button.Button_isPrimary.RmaCreate-Button,
    button.RmaCreate-BackButton {
        --button-height: 55px;
        --button-hover-height: 55px;
    }

    button.Button_isPrimary.RmaCreate-Button {
        margin-top: 40px;

        @include tablet {
            margin-bottom: 35px;
        }
    }

    .RmaCreate-BackButton {
        @include mobile {
            margin-top: 20px;
        }

        @include tablet {
            margin: 40px 0 35px 20px;
        }
    }
}
