@import '../../../../../../src/style/abstract/variables';

@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/placeholder';

.Recaptcha {
    .Field {
        margin-block-start: 0;

        &-ErrorIcon {
            display: none;
            opacity: 0;
            visibility: hidden;
            height: 0;
            width: 0;
        }
    }
}
