@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.Consents {
    &-FieldWrapper {
        display: flex;
        flex-direction: column;

        &:last-of-type {
            margin-bottom: 15px;
        }
    }

    .Field_type_checkbox {
        &.Consents {
            &-Field {
                .input-control {
                    min-width: 16px;
                    min-height: 16px;

                    & ~ span {
                        p:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                .Field-CheckboxLabel {
                    display: flex;
                    align-items: flex-start;
                }

                .Consents-Label_isRequired {
                    display: inline-block;
                    padding-left: 5px;
                }
            }
        }
    }
}
