@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --newsletter-subscription-placeholder-color: $purple80;
}

.NewsletterSubscription {
    &-Agreement {
        display: flex;
    }

    &-AgreementLabel {
        width: calc(100% - 36px);
    }

    .FieldForm-Fieldset {
        .Field_type_checkbox {
            width: auto;
        }
    }

    .Consents {
        .Field {
            [type='checkbox'] {
                + .input-control {
                    &:hover {
                        @include desktop {
                            --box-color: #{$white};
                        }
                    }
                }
            }
        }
    }
}
