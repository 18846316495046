@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --seo-text-heading-color: #{$default-primary-base-color};
    --seo-text-content-color: #{$grey-dark};
}

.SeoText {
    margin: 0 auto;
    padding: 0 15px;
    max-width: var(--content-wrapper-width);

    @include tablet {
        padding: 0 30px;
    }

    &-Heading {
        color: var(--seo-text-heading-color);
        font-family: $font-primary;
        font-weight: bold;
    }

    p,
    .Paragraph {
        color: var(--seo-text-content-color);
    }
}
