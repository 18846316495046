@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.PasswordChangePage {
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
    &-Wrapper {
        padding: 60px var(--content-padding) 100px var(--content-padding);
        margin-left: 0;
        max-width: 500px;

        @include mobileAndTablet {
            max-width: unset;
        }

        h1{
            color: $default-primary-base-color;
            font-size: 30px;
            line-height: 140%;
            letter-spacing: 0.03em;
            margin-bottom: 32px;
        }

        input {
            width: 100%;
        }

        button {
            margin-top: 0;
            @include mobileAndTablet {
                width: 100%;
            }

            &.PasswordChangePage-Button {
                margin-top: 30px;
            }
        }
    }
}
