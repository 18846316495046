@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.CarouselScrollItem {
    margin-left: 0 !important;
    width: 70px;
    height: 80px;
    border: 0;

    & + .CarouselScrollItem {
        margin-top: var(--carousel-scroll-gap);
    }

    .ProductGalleryThumbnailImage {
        padding: 0;

        .ProductGalleryThumbnailImage {
            &.Image {
                height: 80px;
            }
        }
    }
}
