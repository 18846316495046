@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ForgotPassword {
    &-InnerWrapper {
        .Field input {
            --default-input-padding: 16px 24px;
            max-width: unset;
            width: 100%;
        }
        .Button:not(.Button_likeLink) {
            --button-height: 47px;
            --button-hover-height: 47px;
            --button-border-radius: 7px;
            --button-hover-border-radius: 7px;
            letter-spacing: 0.07em;
            font-weight: 700;
            font-size: 15px;
            line-height: 140%;
            font-family: $font-primary;
            margin: 24px 0 0 0;

            @include desktop {
                width: 100%;
            }

            svg {
                display: none;
            }
        }
        .Button_likeLink {
            font-family: $font-secondary;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            color: $default-secondary-base-color;
            margin-top: 16px;
            margin-bottom: 0;
        }
        h3 {
            color: $default-primary-base-color;

            + p {
                margin-bottom: 24px;
            }
        }

        .MyAccountOverlay {
            &-ResetPassword {
                margin-top: 0;
            }
        }

        .MyAccountOverlay-Buttons {
            margin: 0;
        }
        .LoginAccount-CreateAccount {
            display: flex;
            align-items: center;
            h4,
            .Button_likeLink {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 15px;
                line-height: 150%;
            }
            h4 {
                color: $default-primary-base-color;
                letter-spacing: unset;
                margin-right: 6px;
            }
            .Button_likeLink {
                color: $default-secondary-base-color;
            }
        }
    }
    &-CreateAccount,
    &-SignInWrapper {
        @include mobileAndTablet {
            display: flex;
            justify-content: center;
        }

        h4 {
            color: $default-primary-base-color;
            letter-spacing: unset;
            margin-right: 6px;
            padding: 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            font-family: $font-secondary;
            @include mobileAndTablet {
                margin-bottom: 0;
            }
        }
        .Button.Button_likeLink {
            margin: 0;
            min-width: unset;

            @include mobileAndTablet {
                width: auto;
            }
        }
    }

    &-CreateAccount {
        @include mobileAndTablet {
            padding-top: 24px;
            margin-bottom: 8px !important;
        }
    }
}
