h1 {
    font-size: 56px;
    line-height: 150%;
    letter-spacing: -0.03em;
    margin-bottom: 48px;
}

h2 {
    font-size: 42px;
    line-height: 130%;
    margin-bottom: 40px;
}

h3 {
    font-size: 30px;
    line-height: 140%;
    letter-spacing: 0.03em;
    margin-bottom: 32px;
}

h4 {
    font-size: 22px;
    line-height: 135%;
    letter-spacing: 0.07em;
    margin-bottom: 24px;
}

h5 {
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.07em;
    margin-bottom: 20px;
}

h6 {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.05em;
    margin-bottom: 16px;
}
