@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.ReviewStar {
    padding-right: 5px;
    font-size: 20px;
    cursor: pointer;

    &::after {
        content: url('../../assets/svg/star-empty.svg');
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        &:after {
            content: url('../../assets/svg/star-full.svg');
        }
    }
}
